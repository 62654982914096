<script lang="ts" setup>
import { ref } from 'vue';

const url = ref<string | null>();
const show = ref(false);
let oldHref = document.location.href;

function loadUrl() {
	BX.ajax
		.runAction('profistudio:typography.Learning.searchContext', {
			data: { url: document.location.href.replace(/^https?:\/\/[^\/]+/, '') },
		})
		.then((response: ActionResponse) => {
			let toUrl = null;
			if (response.data?.id) {
				toUrl = `/helpcontext/?${response.data.hasChild ? 'CHAPTER_ID=0' : 'LESSON_ID='}${response.data.id}`;
			}
			url.value = toUrl;
		});
}
function open() {
	show.value = true;
}
function close() {
	show.value = false;
}
const observeUrlChange = () => {
	const body = document.querySelector('body');
	const observer = new MutationObserver(() => {
		if (oldHref !== document.location.href) {
			oldHref = document.location.href;
			loadUrl();
		}
	});
	observer.observe(body, { childList: true, subtree: true });
};
window.addEventListener('load', observeUrlChange);
loadUrl();
</script>
<template>
	<a v-if="!!url" class="link-dark" @click.prevent="open()" href="#">
		<i class="fa-regular fa-circle-question"></i>
	</a>

	<div
		style="--bs-offcanvas-width: 75vw; min-width: 320px; max-width: 1400px"
		class="offcanvas offcanvas-end"
		:class="{ show: show }"
		tabindex="-1"
	>
		<button type="button" class="btn-close position-absolute p-4 bg-body-secondary" @click="close()"></button>
		<div class="offcanvas-body p-0" style="overflow-y: hidden">
			<iframe v-if="show" :src="show ? url : null" style="width: 100%; height: 100%"></iframe>
		</div>
	</div>
	<div v-if="show" class="offcanvas-backdrop fade show"></div>
</template>
<style scoped>
.btn-close {
	opacity: 1 !important;
	box-sizing: border-box;
	border-radius: 50% 0 0 50%;
	transform: translate(-100%, 50%);
}
</style>
